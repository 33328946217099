import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { EnvironmentHelper } from "./helpers";

EnvironmentHelper.init().then(() => {
  const root = createRoot(document.getElementById("root"));
  //root.render(<React.StrictMode><App /></React.StrictMode>);
  root.render(<App />);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
